import React, { useEffect, useRef, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "../location/location.css";
import Aos from "aos";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { InfoWindow } from "@react-google-maps/api";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

export default function Location() {
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [page, setPage] = useState(1);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [zoom, setZoom] = useState(16);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAutocompleteValue, setSelectedAutocompleteValue] =
    useState("");
  const [clinicData, setClinicData] = useState();
  const setCenterWithZoom = ({ lat, lng }, newZoom) => {
    setCenter({ lat, lng });
    setZoom(newZoom);
  };

  const mapRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [center, setCenter] = useState({
    lat: 51.5074,
    lng: -0.1278,
  });

  const itemsPerPage = 2;
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(firestore, "accept"))
        );
        const fetchedData = [];

        querySnapshot.forEach(async (doc) => {
          const clinicData = doc.data();

          fetchedData.push({
            name: clinicData.name,
            title: clinicData.practiceName,
            email: clinicData.email,
            website: clinicData.website,
            id: doc.id,
            city: clinicData.city,
            country: clinicData.country,
            postcode: clinicData.postcode,
            phone: clinicData.contactno,
            instagram: clinicData.instagram,
            receptcha: clinicData.receptcha,
            address: clinicData.adress,
            address2: clinicData.adress2,
            lat: parseFloat(clinicData.lat),
            lng: parseFloat(clinicData.lng),
            ...clinicData,
          });
        });
        setZoom(10);
        setLocations(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSendMessage = async () => {
    // Check if all fields are filled
    const { name, email, phoneNumber, message } = formData;
    if (!name || !email || !phoneNumber || !message) {
      toast.error("Please fill out all fields.");

      return;
    }

    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(phoneNumber)) {
      toast.error("Phone number must be exactly 11 digits.");

      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");

      return;
    }

    if (!agreeToTerms) {
      toast.error("Please agree to terms and conditions.");
      return;
    }

    setIsLoading(true);

    const templateParams = {
      from_name: name,
      email: clinicData?.email,
      telephone: phoneNumber,
      message,
      contact: email,
      clinic_name: clinicData?.practiceName,
    };

    try {
      const result = await emailjs.send(
        "service_zr3yk9m",
        "template_8c9aswo",
        templateParams,
        "6dYIgtGj2WFq4uVkF"
      );

      const messageData = {
        ...formData,
        time: serverTimestamp(),
      };

      if (result?.status === 200) {
        await addDoc(collection(firestore, "messages"), messageData);
        setFormData({ name: "", email: "", phoneNumber: "", message: "" });
        setIsLoading(false);
        setOpenDialog(false);
        setSelectedAutocompleteValue("");
        setSearchQuery("");
        toast.success("Email sent successfully");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  const openGoogleMapsLocation = async (address, address2, city) => {
    const querySnapshot = await getDocs(query(collection(firestore, "accept")));
    const fetchedData = [];

    querySnapshot.forEach((doc) => {
      const clinicData = doc.data();

      fetchedData.push({
        address: clinicData.adress,
        lat: parseFloat(clinicData.lat),
        lng: parseFloat(clinicData.lng),
      });
    });

    const lngFilter = fetchedData.filter((x) => x.address === address);

    const { lat, lng } = lngFilter[0];

    setCenterWithZoom({ lat, lng }, 20);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setSelectedAutocompleteValue(event.target.value);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return (distance * 0.621371).toFixed(1); // Return distance in miles, rounded to one decimal place
  };

  // const [selectedLocationDistance, setSelectedLocationDistance] =
  //   useState(null);

  const handlePlaceSelect = () => {
    if (!autocomplete) {
      console.error("Autocomplete is not initialized");
      return;
    }

    const place = autocomplete.getPlace();
    if (!place.geometry) {
      console.log("Place not found");
      return;
    }
    const { lat, lng } = place.geometry.location;
    const selectedLocation = { lat: lat(), lng: lng() };
    setSelectedLocation(selectedLocation);
    setSelectedAutocompleteValue(place.formatted_address);
    setSearchQuery(place.formatted_address);

    setCenter(selectedLocation);
    setZoom(16);
  };

  const filtered = locations.filter(
    (location) =>
      (location.address &&
        location.address.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (location.title &&
        location.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (location.address2 &&
        location.address2.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (location.city &&
        location.city.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (location.postcode &&
        location.postcode.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  useEffect(() => {
    if (filtered.length === 0 && searchQuery.trim() !== "") {
      if (selectedLocation) {
        // Check if selectedLocation is not null
        const distances = locations.map((location) => ({
          ...location,
          distance: calculateDistance(
            selectedLocation.lat,
            selectedLocation.lng,
            location.lat,
            location.lng
          ),
        }));

        distances.sort((a, b) => a.distance - b.distance);
        setFilteredLocations(distances);
        setZoom(15);
      }
    } else {
      setFilteredLocations(
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    }
  }, [searchQuery, locations]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // setSearchQuery(value);
  };

  // Add a new function to sort filteredLocations based on distance from the selected location
  const sortLocationsByDistance = () => {
    const sortedByDistance = filteredLocations.slice().sort((a, b) => {
      return (
        calculateDistance(center.lat, center.lng, a.lat, a.lng) -
        calculateDistance(center.lat, center.lng, b.lat, b.lng)
      );
    });
    setFilteredLocations(sortedByDistance);
  };

  const handleSortByDistance = () => {
    sortLocationsByDistance();
  };

  const handleDialogOpen = (location) => {
    setClinicData(location);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDkWf04MQ9Qi7lqEfFVHN2g1WvvaYOCsjM",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }
  const handleCheckboxChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const getTruncatedUrl = (location) => {
    const urlObject = new URL(location);
    return `${urlObject.protocol}//${urlObject.host}`;
  };

  return (
    <div className="location-main" id="location">
      <div className="location">
        <p className="location-title">Clinic Locator</p>
        <p className="location-text">
          Use the clinic locator to find your nearest practice
        </p>
        <div className="location-comp">
          <div className="location-left" data-aos="fade-right">
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={mapContainerStyle}
              zoom={zoom} // Use zoom state
              center={center}
              onLoad={(map) => {
                mapRef.current = map; // Save the map instance to mapRef
                map.setZoom(zoom); // Set initial zoom level
              }}
              onZoomChanged={() => {
                const mapInstance = mapRef.current; // Access the saved map instance
                if (mapInstance && typeof mapInstance.getZoom === "function") {
                  setZoom(mapInstance.getZoom()); // Update zoom state when zoom changes
                }
              }}
            >
              {filteredLocations.map((location) => (
                <Marker
                  key={location.id}
                  position={{ lat: +location.lat, lng: +location.lng }}
                  onClick={() => setSelectedMarker(location)} // Set selected marker
                />
              ))}
              <>
                {selectedMarker && (
                  <InfoWindow
                    position={{
                      lat: selectedMarker.lat,
                      lng: selectedMarker.lng,
                    }}
                    onCloseClick={() => setSelectedMarker(null)} // Deselect marker when closing info window
                  >
                    <div>
                      <p>{selectedMarker.title}</p>{" "}
                    </div>
                  </InfoWindow>
                )}
              </>
            </GoogleMap>
          </div>
          <div className="location-right" data-aos="fade-left">
            <div className="location-cont">
              <div className="location-search">
                <div style={{ width: "100%" }}>
                  <Autocomplete
                    onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                    onPlaceChanged={handlePlaceSelect}
                  >
                    <input
                      type="text"
                      placeholder="Enter post code/town"
                      value={selectedAutocompleteValue}
                      onChange={handleSearchInputChange}
                      style={{ width: "100%" }}
                    />
                  </Autocomplete>
                </div>
                <button onClick={handleSortByDistance}>
                  <img src="loc-button.svg" alt="" />
                </button>
              </div>

              <div className="location-box">
                {filteredLocations
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((location) => (
                    <div key={location.id} className="location-padding">
                      <div className="upper-box">
                        <p>{location.title}</p>
                        <div className="location-icons">
                          <button
                            onClick={() =>
                              openGoogleMapsLocation(location.address)
                            }
                          >
                            <img src="location.svg" alt="" />
                          </button>
                          <button
                            className="button2"
                            onClick={() => handleDialogOpen(location)}
                          >
                            Contact Practice
                          </button>
                        </div>
                      </div>
                      <div className="lower-box">
                        <div className="location-info">
                          <img src="Vector.svg" alt="" />
                          <p>
                            {location.address} ,{location.address2}{" "}
                            {location.city}, {location.postcode}
                          </p>
                        </div>
                        <a href={`tel:${location.phone}`}>
                          <div className="location-info">
                            <img src="Vector1.svg" alt="" />
                            <p>{location.phone}</p>
                          </div>
                        </a>
                        <a
                          href={location.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="location-info">
                            <img src="Vector2.svg" alt="" />
                            <p>
                              {
                                // getTruncatedUrl(location?.website) ||
                                location.website
                              }
                            </p>
                          </div>
                        </a>
                        {selectedLocation && searchQuery.trim() !== "" && (
                          <div className="location-info">
                            <img src="distance.png" alt="" />
                            <p>
                              {(location.distance
                                ? location.distance / 0.621371
                                : 0
                              ).toFixed(1)}{" "}
                              miles away
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              <div
                className="pagination"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "22px",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(filteredLocations.length / itemsPerPage)}
                    page={page}
                    onChange={handleChange}
                    sx={{
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: "rgba(136, 60, 136, 1)",
                        color: "white",
                      },
                    }}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog for Contact Practice */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle style={{ justifyContent: "center", display: "flex" }}>
          Contact Practice
        </DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <div
            className="dilog-inputs"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              required
              onChange={handleInputChange}
            />
            <input
              type="number"
              placeholder="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <textarea
              name="message"
              cols="30"
              rows="4"
              placeholder="Message"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            <div className="terms-check">
              <input
                type="checkbox"
                class="ui-checkbox"
                name="agreeToTerms"
                checked={agreeToTerms}
                onChange={handleCheckboxChange}
                style={{ height: "13px", width: "20px", marginBottom: "44px" }}
              />
              <p>
                I confirm that I have read and understood wakeupandsmile's{" "}
                <Link
                  to="/privacy-policy"
                  style={{
                    fontSize: "15px",
                    color: "rgba(136, 60, 136, 1)",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Privacy Policy
                </Link>
                , which sets out information about how SmileFast will use my
                personal data
              </p>
            </div>
            <div className="dilog-recepathch"></div>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleSendMessage}
            style={{
              backgroundColor: "rgba(136, 60, 136, 1",
              color: "white",
              paddingTop: "10px",
              width: "30%",
              height: "40px",
              marginBottom: "20px",
            }}
          >
            {isLoading ? (
              <>
                <div class="loader"></div>
              </>
            ) : (
              "Send Message"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
