import { useState, useEffect } from "react";
import "../questions/questions.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Questions() {
  const faqData = [
    {
      question: "How long do I have to wear NiTime aligners each day?",
      answer: "The aligners need to be worn for a continuous 9-12 hours a day.",
    },
    {
      question: "I don’t sleep for 9 hours, will it still work for me?",
      answer:
        "It’s worth brushing your teeth after the last meal of the day and putting the aligners in then until the morning.",
    },
    {
      question:
        "Will I have to wear the NiTime aligners longer than normal all day aligners?",
      answer:
        "No, that’s the impressive bit. Opting for NiTime aligners will not extend the length of your treatment compared to if you wore other brands for 22 hours a day.",
    },
    {
      question: "Will I experience more discomfort with NiTime aligners?",
      answer:
        "NiTime aligners may be more comfortable than industry-standard aligners. NiTime aligners use smaller but optimal forces, whereas some other aligners exert more force than what is required causing more discomfort.",
    },
  ];

  const [showAnswers, setShowAnswers] = useState(
    Array(faqData.length).fill(false)
  );

  const toggleAnswer = (index) => {
    const newShowAnswers = [...showAnswers];
    newShowAnswers[index] = !newShowAnswers[index];
    setShowAnswers(newShowAnswers);
  };

  return (
    <>
      <div className="questions-main">
        <div className="questions">
          <div className="questions-text">
            <p>Frequently Asked Questions</p>
          </div>
          <div className="questions-data">
            {faqData.map((item, index) => (
              <div
                key={index}
                className={`question ${
                  showAnswers[index] ? "answer-shown" : "answer-hidden"
                }`}
              >
                <div
                  className="question-mark"
                  onClick={() => toggleAnswer(index)}
                >
                  <p>{item.question}</p>
                  <img
                    src={showAnswers[index] ? "icon.png" : "question.png"}
                    alt=""
                  />
                </div>
                <div
                  className={`answer ${
                    showAnswers[index] ? "" : "answer-hidden"
                  }`}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
