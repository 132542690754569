import React, { useState, useEffect } from "react";
import Appbar from "./components/appbar/appbar";
import Choose from "./components/choose/choose";
import Coffee from "./components/coffee/coffee";
import Conven from "./components/convenience/conven";
import Eat from "./components/eat/eat";
import Find from "./components/find/find";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Location from "./components/location/location";
import Night from "./components/night/night";
import Questions from "./components/questions/questions";
import Work from "./components/work/work";
import "./home.css";

export default function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      setLoading(false);
    });
  
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds
  
    return () => clearTimeout(timeout); // Cleanup function to clear timeout
  }, []);
  

  
  return (
    <>
     {loading && (
          <div className="preloaderBg" id="preloader">
            <div className="preloader-wrap">
              <div className="preloader-logo"></div>
              <div className="preloader-circle"></div>
            </div>
          </div>
        )}
      <div style={{ overflow: "hidden" }}>
       
        <div className="home-flex">
          <div className="home-main">
            <img src="background.png" alt="" />
            <img src="background2.svg" alt="" />
          </div>
        </div>

        <div className="home">
          <div style={{width:"100%",height:"100px"}}>

          </div>
          <Appbar />
          <div className="header-container">
            <Header />
          </div>
        </div>
        <Find />
        <Conven />
        <Coffee />
        <Eat />
        <Work />
        <Night />
        <Choose />
        <Questions />
        <Location />
        <Footer />
      </div>
    </>
  );
}
