import "../appbar/appbar.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Appbar() {
    const [scrollOffset, setScrollOffset] = useState(0); 


    const scrollOptions = {
        duration: 800,
        smooth: true,
    };

    const scrollToNextComponent = () => {
        const nextComponentId = 'location'; 
        const nextComponent = document.getElementById(nextComponentId);
    
        if (nextComponent) {
            const targetTop = nextComponent.offsetTop;

            const offsetFromBottom = 150;

            const finalScrollPosition = targetTop - offsetFromBottom;

            setScrollOffset(finalScrollPosition);

            window.scrollTo({
                top: finalScrollPosition,
                behavior: 'smooth'
            });
        }
    };
    return (
        <>
            <div className="appbar-main">
                <div className="appbar">
                <Navbar expand="lg" className="" justify-content-between style={{width:"100%",backgroundColor:"transparent"}}>
    
        <Navbar.Brand href="/"><img src="logo.png" alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav">
         <div className="appbar-button">
         <Link onClick={scrollToNextComponent}>
         <button>
          Find your nearest provider
          </button>
         </Link>
         </div>
        </Navbar.Collapse>
     
    </Navbar>
                </div>
            </div>
        </>
    );
}
