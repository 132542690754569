import React, { useEffect, useRef } from "react";
import "../convenience/conven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import background from '../assets/main-background.svg';


// Sample data for the cards
const cardData = [
  {
    id: 1,
    imageUrl: "person.png",
    description: "Before bed brush your teeth and do your regular nightly routine. Put your aligners in."
  },
  {
    id: 2,
    imageUrl: "profile2.png",
    description: "Dream. Wake up closer to the smile that you have always dreamed of."
  },
  {
    id: 3,
    imageUrl: "profile3.png",
    description: "Go about your day with the freedom of not wearing any aligners."
  }
];

export default function Conven() {
  const sectionRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });

  }, []);

  return (
    <div className="cards-main" ref={sectionRef}>
      <div className="main-background">
        <img src={background} alt="" />
      </div>
      <div className="cards">
        <p>
          Live with the convenience,<strong> flexibility and freedom </strong> of NiTime aligners
        </p>
        <div className="con-cards">
          {cardData.map((card, index) => (
            <div className="cardss" key={card.id} data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}>
              <div className="card-img">
                <img src={card.imageUrl} alt="" />
              </div>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
