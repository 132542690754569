import ReCAPTCHA from "react-google-recaptcha";
import "../add-clinic/clinic.css";
import Appbar from "../components/appbar/appbar";
import Footer from "../components/footer/footer";
import { useState } from "react";
import { firestore } from "../firebase.js";
import { Timestamp, addDoc, collection, doc, setDoc } from "firebase/firestore";
import { Box, Button, Grid, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Clinic() {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "fill all the field",
  });

  const [formData, setFormData] = useState({
    dentistName: "",
    practiceName: "",
    streetAddress: "",
    streetAddressLine2: "",
    city: "",
    postCode: "",
    country: "",
    practiceContactNo: "", // Modified field to include UK country code
    practiceEmailAddress: "",
    practiceWebAddress: "",
    practiceInstagramAddress: "",
    agreeToTerms: false,
    recaptchaToken: "",
    lat: "",
    lng: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      "dentistName",
      "practiceName",
      "practiceContactNo",
      "practiceEmailAddress",
      "practiceWebAddress",
      "city",
      "postCode",
      "country",
    ];

    const isFormValid = requiredFields.every((field) => formData[field] !== "");

    const isInstagramFilled = formData.practiceInstagramAddress !== "";

    if (!isFormValid && !isInstagramFilled) {
      setLoading(false);
      toast.error("Please fill in all required fields.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.practiceEmailAddress)) {
      setLoading(false);
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!formData.agreeToTerms) {
      setLoading(false);
      toast.error("Please agree to the terms and conditions.");
      return;
    }

    if (formData.practiceContactNo.replace(/\s/g, "").length !== 11) {
      setLoading(false);
      toast.error("Please enter a valid phone number.");
      return;
    }

    // Postal code validation
    if (formData.postCode.length < 7) {
      setLoading(false);
      toast.error("Please enter a valid postal code (minimum 7 characters).");
      return;
    }

    const currentTime = Timestamp.now();

    const formDataWithTimestamp = {
      ...formData,
      Date: currentTime,
    };

    const fullAddress = `${formDataWithTimestamp?.streetAddress}, ${formDataWithTimestamp?.streetAddressLine2}, ${formDataWithTimestamp.city}`;

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          fullAddress
        )}&key=AIzaSyDkWf04MQ9Qi7lqEfFVHN2g1WvvaYOCsjM`
      );

      const data = await response.json();

      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;

        const currentTime = Timestamp.now();

        // Add coordinates to formDataWithTimestamp
        if (lat && lng) {
          setTimeout(() => {
            setFormData((prevData) => ({
              ...prevData,
              Date: currentTime,
              lat: lat,
              lng: lng,
            }));
          }, 500);
          setTimeout(() => {
            try {
              const formDataWithTimestamp = {
                ...formData,
                Date: currentTime,
                lat: lat,
                lng: lng,
              };

              addDoc(collection(firestore, "clinics"), formDataWithTimestamp);
              setLoading(false);
              toast.success("Form submitted successfully!");
            } catch (error) {
              console.error("Error saving form data:", error);
              toast.error("An error occurred while submitting the form.");
            }
          }, 700);
        }

        // Add coordinates to formDataWithTimestamp

        // Submit formDataWithTimestamp to your Firestore
      } else {
        setLoading(false);
        toast.error("Geocoding failed. Please check the address.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({
      ...notification,
      isOpen: false,
    });
  };

  return (
    <>
      <ToastContainer />
      <form
        className="main-clinic-fields"
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="clinic-main">
          <div className="clinic">
            <Appbar />
          </div>
        </div>
        <div className="clinic-request-main">
          <div className="clinic-request">
            <p>Addition to Clinic Locator Request</p>
            <p>
              Please complete this form to request having your practice added to
              the SmileFast Clinic Locator.
            </p>
          </div>
        </div>
        <div className="main-clinic-fields">
          <div className="clinic-fields">
            <div className="field1">
              <div className="fields">
                <p>Dentist Name *</p>
                <input
                  type="text"
                  name="dentistName"
                  value={formData.dentistName}
                  onChange={handleChange}
                />
              </div>
              <div className="fields">
                <p>Practice Name *</p>
                <input
                  type="text"
                  name="practiceName"
                  value={formData.practiceName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field2">
              <div className="fields">
                <p>Street Address</p>
                <input
                  type="text"
                  name="streetAddress"
                  value={formData.streetAddress}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field2">
              <div className="fields">
                <p>Street Address Line 2</p>
                <input
                  type="text"
                  name="streetAddressLine2"
                  value={formData.streetAddressLine2}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field1">
              <div className="fields">
                <p>City</p>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="fields">
                <p>Post Code</p>
                <input
                  type="text"
                  name="postCode"
                  value={formData.postCode}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field2">
              <div className="fields">
                <p>Country</p>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field1">
              <div className="fields">
                <p>Practice Contact No *</p>
                <input
                  type="text"
                  name="practiceContactNo"
                  value={formData.practiceContactNo}
                  onChange={handleChange}
                  maxLength="11"
                />
              </div>
              <div className="fields">
                <p>Practice E-mail Address *</p>
                <input
                  type="text"
                  name="practiceEmailAddress"
                  value={formData.practiceEmailAddress}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field1">
              <div className="fields">
                <p>Practice Web Address *</p>
                <input
                  type="text"
                  name="practiceWebAddress"
                  value={formData.practiceWebAddress}
                  onChange={handleChange}
                />
              </div>
              <div className="fields">
                <p>Practice Instagram Address</p>
                <input
                  type="text"
                  name="practiceInstagramAddress"
                  value={formData.practiceInstagramAddress}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="clinic-terms-main">
          <div className="clinic-terms">
            <p className="terms-head">DENTIST DATA SHARING TERMS</p>
            <p className="terms-para">
              Smile Fast Limited uses its website www.smilefast.com to allow
              website users to contact dental practices to discuss their dental
              treatment options.
            </p>
            <p className="terms-para">
              As we are sharing personal data of individuals with dental
              practices via our website, UK data protection laws apply and both
              Smile Fast Limited and the dental practices (the parties) have
              various obligations in relation to protecting the personal data
              being shared.
            </p>
            <p className="terms-para">
              By receiving personal data from Smile Fast Limited, you the dental
              practice are deemed to accept the data sharing terms set out
              below.
            </p>
            <p className="terms-head">DEFINITIONS</p>
            <p className="terms-para">
              Agreed Purposes: personal data is shared by SmileFast Limited to
              allow dental practices to contact interested patients who have
              specifically requested to be contacted by the dental practice to
              understand more about their dental treatment options.
            </p>
            <br />
            <p className="terms-para">
              Controller, processor, data subject, personal data, personal data
              breach, processing and a ppropriate technical and organisational
              measures: have the meaning as set out in the Data Protection
              Legislation.{" "}
            </p>
            <br />
            <p className="terms-para">
              Disclosing Party: Smile Fast Limited, the party that discloses
              Shared Personal Data to the dental practice.
            </p>
            <br />
            <p className="terms-para">
              Data Protection Legislation: all applicable data protection and
              privacy legislation in force from time to time in the UK including
              the UK GDPR; the Data Protection Act 2018 (DPA 2018) (and
              regulations made thereunder); the Privacy and Electronic
              Communications Regulations 2003 (SI 2003/2426) as amended from
              time to time.
            </p>
            <br />
            <p className="terms-para">
              UK GDPR: has the meaning given to it in section 3(10) (as
              supplemented by section 205(4)) of the Data Protection Act 2018.
            </p>
            <br />
            <p className="terms-para">
              Permitted Recipients: the parties to this agreement and the
              employees of each party. Receiving Party: the dental practice
              receiving the Shared Personal Data.
            </p>
            <br />
            <p className="terms-head">Shared Personal</p> <br />
            <p className="terms-para">
              a) Name; <br />
              b) Email address, and <br />
              c) Telephone number.
            </p>
            <br />
            <p className="terms-para">
              1. DATA PROTECTION <br />
            </p>
            <br />
            <p className="terms-para">
              1.1 This contract sets out the framework for the sharing of
              personal data between the parties as controllers. Each party
              acknowledges that the Disclosing Party will regularly disclose to
              the Receiving Party Shared Personal Data collected by the
              Disclosing Party for the Agreed Purposes. Each party shall comply
              with all the obligations imposed on a controller under the Data
              Protection Legislation.
            </p>
            <br />
            <p className="terms-para">
              1.2 The Disclosing Party shall ensure prior to sharing the Shared
              Personal Data with the Receiving Party that a privacy policy has
              been made available to each relevant Data Subject in accordance
              with its obligations under the Data Protection Legislation.
            </p>
            <br />
            <p className="terms-para">.3 The Receiving Party shall:</p>
            <p className="terms-para">
              (a) give full information to any data subject whose personal data
              may be processed under this agreement of the nature of such
              processing;
            </p>
            <p className="terms-para">
              (b) process the Shared Personal Data only for the Agreed Purposes;
            </p>
            <p className="terms-para">
              (c) not disclose or allow access to the Shared Personal Data to
              anyone other than the Permitted Recipients;
            </p>
            <p className="terms-para">
              (d) ensure that all Permitted Recipients are subject to written
              contractual obligations concerning the Shared Personal Data
              (including obligations of confidentiality) which are no less
              onerous than those imposed by this agreement;
            </p>
            <p className="terms-para">
              (e) ensure that it has in place appropriate technical and
              organisational measures to protect against unauthorised or
              unlawful processing of personal data and against accidental loss
              or destruction of, or damage to, personal data; and
            </p>
            <p className="terms-para">
              (f) not transfer any personal data received from the Disclosing
              Party outside the UK.
            </p>{" "}
            <br />
            <p className="terms-para">
              1.4 Mutual assistance. Each party shall assist the other in
              complying with all applicable requirements of the Data Protection
              Legislation. In particular, each party shall:{" "}
            </p>
            <p className="terms-para">
              (a) consult with the other party about any notices given to data
              subjects in relation to the Shared Personal Data;
            </p>
            <p className="terms-para">
              (b) promptly inform the other party about the receipt of any data
              subject rights request;
            </p>
            <p className="terms-para">
              (c) provide the other party with reasonable assistance in
              complying with any data subject rights request;
            </p>
            <p className="terms-para">
              (d) not disclose, release, amend, delete or block any Shared
              Personal Data in response to a data subject rights request without
              first consulting the other party wherever possible;
            </p>
            <p className="terms-para">
              (e) assist the other party in responding to any request from a
              data subject and in ensuring compliance with its obligations under
              the Data Protection Legislation with respect to security, personal
              data breach notifications, data protection impact assessments and
              consultations with the Information Commissioner or other
              regulators;
            </p>
            <p className="terms-para">
              (f) notify the other party without undue delay on becoming aware
              of any breach of the Data Protection Legislation; and
            </p>{" "}
            <p className="terms-para">
              (g) maintain complete and accurate records and information to
              demonstrate its compliance with this agreement.
            </p>{" "}
            <br />
            <p className="terms-para">
              1.5 This agreement and any dispute or claim (including
              non-contractual disputes or claims) arising out of or in
              connection with it or its subject matter or formation shall be
              governed by and construed in accordance with the law of England
              and Wales.
            </p>{" "}
            <br />
            <p className="terms-para">
              1.6 Each party irrevocably agrees that the courts of England and
              Wales shall have exclusive jurisdiction to settle any dispute or
              claim (including non-contractual disputes or claims), arising out
              of or in connection with this Agreement or its subject matter or
              formation.
            </p>{" "}
            <br /> <br />
          </div>
        </div>
        <div className="terms-confirm-main">
          <div className="terms-confirm">
            <p>
              I agree all the information I have provided is true and accurate *
            </p>
            <div className="terms-check">
              <input
                type="checkbox"
                class="ui-checkbox"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleCheckboxChange}
              />
              <p>
                I agree to the Dentist Data Sharing Terms and confirm the
                details provided are correct
              </p>
            </div>
          </div>
        </div>
        <div className="robot">
          <Grid item xs={6} textAlign="right">
            <Button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send"}
            </Button>
          </Grid>
        </div>
      </form>
      <Footer />
    </>
  );
}
