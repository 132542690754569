import React, { useEffect, useState } from "react";
import "../find/find.css"; // Adjust the path accordingly
import AOS from "aos";
import "aos/dist/aos.css";

export default function Find() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation
    });
  }, []);

  const [scrollOffset, setScrollOffset] = useState(0);

  const scrollToNextComponent = () => {
    const nextComponentId = "location";
    const nextComponent = document.getElementById(nextComponentId);

    if (nextComponent) {
      const targetTop = nextComponent.offsetTop;

      const offsetFromBottom = 150;

      const finalScrollPosition = targetTop - offsetFromBottom;

      setScrollOffset(finalScrollPosition);

      window.scrollTo({
        top: finalScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="find-main">
      <div className="find" data-aos="fade-up">
        <div className="find-left" data-aos="fade-right">
          <p className="find-title">
            Wake up closer to the <strong>smile</strong> that you have always
            dreamed of.
          </p>
          <p className="find-text">
            Welcome to the only place you will find dentists who can offer the
            world’s only 9 - 12 hour NiTime aligner which won’t extend your
            treatment time.
          </p>
          <button onClick={scrollToNextComponent} className="find-button">
            Find your nearest provider
          </button>
        </div>
        <div className="find-right" data-aos="fade-left">
          <div className="left-img">
            <div className="image-container">
              <img src="find1.png" alt="" />
              <button className="overlay-button">
                You deserve a beautiful smile.
              </button>
            </div>
          </div>
          <div className="right-right">
            <div className="right-img">
              <img src="find2.png" alt="" />
            </div>
            <div className="right-cont">
              <div className="pp">
                <p className="p1">
                  10,000<strong>+</strong>
                </p>
                <p className="p2">
                  Patients with smiles perfected while they slept!
                </p>
              </div>
              <div className="find-images">
                <img src="person1.png" alt="Profile 1" className="profile" />
                <img src="person2.png" alt="Profile 2" className="profile" />
                <img src="person3.png" alt="Profile 3" className="profile" />
                <img src="person4.png" alt="Profile 4" className="profile" />
                <img src="person5.png" alt="Profile 5" className="profile" />
                <img src="person6.png" alt="Profile 6" className="profile" />
                <img src="person7.png" alt="Profile 7" className="profile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
