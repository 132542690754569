import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../coffee/coffee.css";

export default function Coffee() {
  useEffect(() => {
    AOS.init({
      once: false,
      offset: 100,
      duration: 800,
    });
  }, []);

  const [scrollOffset, setScrollOffset] = useState(0);

  const scrollToNextComponent = () => {
    const nextComponentId = "location";
    const nextComponent = document.getElementById(nextComponentId);

    if (nextComponent) {
      const targetTop = nextComponent.offsetTop;

      const offsetFromBottom = 150;

      const finalScrollPosition = targetTop - offsetFromBottom;

      setScrollOffset(finalScrollPosition);

      window.scrollTo({
        top: finalScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="coffee-main">
        <div className="coffee" data-aos="fade-up">
          <div className="left-coffee">
            <p className="coffee-title">
              Want that coffee? <br />
              No problem. <br />
              <strong> No aligners.</strong>
            </p>
            <p className="coffee-text">
              Don’t worry about leaving the house with your aligners, so you can
              start your day as normal with a coffee.
            </p>
            <button onClick={scrollToNextComponent} className="coffee-button">
              Find your nearest provider
            </button>
          </div>
          <div
            className="right-coffee"
            data-aos="fade-down"
            data-aos-delay="500"
          >
            <img src="coffee.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
