import Appbar from "../components/appbar/appbar";
import Footer from "../components/footer/footer";


export default function Cookies(){
    return(
        <>
        <section class="terms-main">
            <div class="terms">
                <Appbar/>
                <div class=" pb-5 text-blue">
                    <h3 class="">Cookie Policy</h3>
                    <div class="uncont">
                        <div class="text-blue" data-delay="100">
                            Our website uses cookies to distinguish you from other users of our website. This helps us
                            to provide
                            you with a good experience when you browse our website and also allows us to improve our
                            site.
                            <br /> <br />A cookie is a small file of letters and numbers that we store on your browser or the
                            hard drive
                            of your computer if you agree. Cookies contain information that is transferred to your
                            computer's hard
                            drive.
                             <br /> <br />We use the following cookies:
                            <ul>
                                <li>Strictly necessary cookies. These are cookies that are required for the operation of
                                    our
                                    website. They include, for example, cookies that enable you to log into secure areas
                                    of our
                                    website.
                                </li>

                            </ul>
                             <br />
                            You can find more information about the individual cookies we use and the purposes for which
                            we use them
                            in the table below:
                             <br />
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                Cookie Title
                                                 <br /> <br />Cookie Name

                                            </th>
                                            <th>
                                                Purpose
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>laravel_session</td>
                                            <td>To identify a session instance for a user, cookie expires after two
                                                hours.</td>
                                        </tr>
                                        <tr>
                                            <td>XSRF-TOKEN</td>
                                            <td>This cookie is written to help with site security in preventing
                                                cross-site request
                                                forgery attacks.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            We do not share the information collected by the cookies with any third parties.

                             <br /> <br />If you do not want to accept any cookies which are essential to the services
                            requested, you may
                            be able to change your browser settings so that cookies are not accepted. However, if you
                            block all
                            essential cookies then please be aware that you may lose some of the functionality of our
                            website and
                            not be able to access all or parts of our website. For further information about cookies and
                            how to
                            disable them please go to the <a  target="_blank" href="">guidance on cookies</a>
                            published by the UK Information Commissioner’s Office,
                            <a  target="_blank" href="">www.allaboutcookies.org</a>.

                        </div>

                    </div>
                </div>
        </div></section>
        <Footer/>
        </>
    )
}