import React, { useEffect } from 'react';
import "../header/header.css"; // Adjust the path accordingly
import AOS from 'aos';
import 'aos/dist/aos.css';

const profileImages = [
  "profile1.png",
  "profile2.png",
  "profile3.png",
  "profile4.png",
  "profile5.png"
];

export default function Header() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation
      once: true, // only once animation on scroll
    });
  }, []);

  return (
    <div className="header-main">
      <div className="header" data-aos="fade-up">
        <div className="header-left" data-aos="fade-right">
          <div className="header-cont">
            <p className="header-title">
              Don’t lie awake worrying about your smile.
            </p>
            <p className="header-text">
              Welcome to the only place in the UK to offer{" "}
              <strong>9 - 12</strong> hour NiTime aligners
            </p><br />
            {/* <button className="header-button">Get in touch</button> */}
            <p className="header-2text">
              Live with the convenience, flexibility and freedom of NiTime
              aligners.
            </p>
          </div>
          <div className="ratings">
            <div className="profile-images">
              {profileImages.map((imageUrl, index) => (
                <div
                  className="profile-image"
                  key={index}
                  style={{ backgroundImage: `url(${imageUrl})` }}
                  
                ></div>
              ))}
            </div>
            <p>
            10,000+ patients treated worldwide
            </p>
          </div>
        </div>
        <div className="header-right" data-aos="fade-left">
          {/* <img src="background.png" alt="" /> */}
        </div>
      </div>
    </div>
  );
}
