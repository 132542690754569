import React, { useEffect } from "react";
import { Link, useNavigate,  } from "react-router-dom";
import "../footer/footer.css";

export default function Footer() {

       const scrollToTop =() =>{
        window.scrollTo(0, 0);
       }
 
  return (
    <>
      <div className="footer-main">
        <div className="footer">
          <div className="footer-links1">
            <Link to="/terms-of-use" onClick={scrollToTop} style={{ color: "white", textDecoration: "none" }}>
              <p>Terms Of Use</p>
            </Link>
            <Link to="/privacy-policy" onClick={scrollToTop} style={{ color: "white", textDecoration: "none" }}>
              <p>Privacy Policy</p>
            </Link>
            <Link to="/cookies" onClick={scrollToTop} style={{ color: "white", textDecoration: "none" }}>
              <p>Cookie Policy</p>
            </Link>
          </div>
          <div className="footer-links2">
          <a target="_blank" href="https://form.jotform.com/231855216321349">
          <button>
          Interested in providing NiTime aligners?  
          </button>
          
          </a>
         
          </div>
          <div className="footer-links">
            <p>© 2024 SmileFast Ltd</p>
          </div>
        </div>
      </div>
    </>
  );
}
