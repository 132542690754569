import "../choose/choose.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";

AOS.init();


export default function Choose(){
    const [scrollOffset, setScrollOffset] = useState(0); 

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animations (in milliseconds)
        });
        AOS.refresh();
    }, []);
    const scrollToNextComponent = () => {
        const nextComponentId = 'location'; 
        const nextComponent = document.getElementById(nextComponentId);
    
        if (nextComponent) {
            const targetTop = nextComponent.offsetTop;

            const offsetFromBottom = 150;

            const finalScrollPosition = targetTop - offsetFromBottom;

            setScrollOffset(finalScrollPosition);

            window.scrollTo({
                top: finalScrollPosition,
                behavior: 'smooth'
            });
        }
    };
 
    return(
        <>
         <div className="chose-main">
            <div className="chose">
               <div className="chose-text">
               <p className="chose-text1">
                Why <strong>choose</strong> us?
                </p>
                <p className="chose-text2">
                NiTime Aligners are unique in what they offer!
                </p>
               </div>
               <div className="chose-cards">
                <div className="chose-card1" data-aos="fade-right">
                    <div className="card1-head">
                        <p>
                        Other Aligner Companies
                        </p>
                    </div>
                   <div className="card-content">
                   <div className="card-list">
                        <p>
                        &#x2715;
                        </p>
                        <p>
                        22 hour wear aligners
                                                </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        Old polymer materials

                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        3rd party remote monitoring
                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        Minimal aftercare support
                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        Excessive dental visits
                                                </p>
                    </div>
                   </div>
                </div>
                <div className="chose-card2" data-aos="fade">
                <div className="card2-head">
                    <p>
                    NiTime Aligners
                    </p>
                </div>
                <div className="card2-button">
                    <button onClick={scrollToNextComponent}>
                    Get Started Now
                    </button>
                </div>
                <div className="card2-content">
                      <div className="card2-list">
                        <img src="tick.svg" alt="" />
                        <p>
                        The only FDA approved NiTime aligner
                        </p>
                      </div>
                      <div className="card2-list">
                      <img src="tick.svg" alt="" />
                        <p>
                        Only 9-12 hours wear needed
                        </p>
                      </div>
                      <div className="card2-list">
                      <img src="tick.svg" alt="" />
                        <p>
                        See the dentist regularly
                       </p>
                      </div>
                      <div className="card2-list">
                      <img src="tick.svg" alt="" />
                        <p>
                        Remote monitoring available if needed                      </p>
                      </div>
                      <div className="card2-list">
                      <img src="tick.svg" alt="" />
                        <p>
                        NiTime approved dentists nationwide
                                       </p>
                      </div>
                </div>


                </div>
                <div className="chose-card1" data-aos="fade-left">
                <div className="card1-head">
                        <p>
                        Direct To Home
                        </p>
                    </div>
                   <div className="card-content">
                   <div className="card-list">
                        <p>
                        &#x2715;
                        </p>
                        <p>
                        Home impressions can be inaccurate
                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        No dental appointments
                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        No added extras included                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        No attachments for precise results
                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        Lack of solutions for complex cases
                        </p>
                    </div>
                    <div className="card-list">
                    <p>
                        &#x2715;
                        </p>
                        <p>
                        No IPR if needed                        </p>
                    </div>
                   </div>
                </div>
               </div>
            </div>
         </div>
        </>
    )
}