import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../eat/eat.css";

export default function Night() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });
  }, []);

  const [scrollOffset, setScrollOffset] = useState(0);

  const scrollToNextComponent = () => {
    const nextComponentId = "location";
    const nextComponent = document.getElementById(nextComponentId);

    if (nextComponent) {
      const targetTop = nextComponent.offsetTop;

      const offsetFromBottom = 150;

      const finalScrollPosition = targetTop - offsetFromBottom;

      setScrollOffset(finalScrollPosition);

      window.scrollTo({
        top: finalScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="eat-main">
        <div className="eat" data-aos="fade-up">
          <div className="left-eat">
            <div className="eat-flex">
              <p className="eat-title">
                Only wear <strong>your aligners</strong> at night!
              </p>
              <p className="eat-text">
                No one needs to know you’re straightening your teeth, its your
                NiTime secret ;
              </p>
              <button onClick={scrollToNextComponent} className="eat-button">
                Contact Now
              </button>
            </div>
          </div>
          <div className="right-eat" data-aos="fade-down" data-aos-delay="500">
            <img src="sleep.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
