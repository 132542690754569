import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../work/work.css";

export default function Work() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });
  }, []);

  const [scrollOffset, setScrollOffset] = useState(0);

  const scrollToNextComponent = () => {
    const nextComponentId = "location";
    const nextComponent = document.getElementById(nextComponentId);

    if (nextComponent) {
      const targetTop = nextComponent.offsetTop;

      const offsetFromBottom = 150;

      const finalScrollPosition = targetTop - offsetFromBottom;

      setScrollOffset(finalScrollPosition);

      window.scrollTo({
        top: finalScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="work-main">
        <div className="work" data-aos="fade-up">
          <div className="left-work">
            <p className="work-title">
              Work without <strong>any aligners</strong> during the day.
            </p>
            <p className="work-text">
              Go to work, have your meetings, chat to colleagues - no lisp
              and no irritations.
            </p>
            <button onClick={scrollToNextComponent} className="work-button">
              Find your nearest provider
            </button>
          </div>
          <div className="right-work" data-aos="fade-down" data-aos-delay="500">
            <img src="eat.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
